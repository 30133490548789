import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Callout from '../components/public/callout';
import Pricing from '../components/public/pricing';
import Faq from '../components/public/faq';
import SEO from '../components/seo';

const PricingPage = () => (
	<Layout>
		<SEO
			title="DnsAdBlock Pricing"
			description={`Transparent pricing and a detailed overview of our ad blocking offering. Keep yourself and your family safe while browsing the internet.`}
		/>

		<Hero title={'Pricing'} style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default PricingPage;
